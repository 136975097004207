import React from 'react'
import { CFImage, CFLink, CFView } from 'components'
import { cflogo } from 'images'

export default () => {
  return (
    <CFView column center pv="10px" bg="black">
      <CFView h6 color="white" content pb="5px">
        COPYRIGHT © 2020 CODEFUSION INC.
      </CFView>
      <CFLink href="https://codefusion.tech">
        <CFImage h="20px" src={cflogo} alt="CodeFusion" />
      </CFLink>
    </CFView>
  )
}
